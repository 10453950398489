import { FC } from 'react'
import { LinkFieldType, MediaFieldType, MediaRatiosType } from '@magal/models'
import { styled } from '@magal/styles'
import { Link, Media } from '@magal/components'

type CardProps = {
  title?: string
  link?: LinkFieldType
  subtitle?: string
  image?: MediaFieldType
  ratio?: MediaRatiosType
}

const PromoTileWrap = styled('div', {
  color: '$green_09',
})
const PromoTileTitle = styled('div', {
  projectFont: 'caps03',
  marginBottom: '$12',
  maxWidth: '250px',
})
const PromoTileSubtitle = styled('div', {
  projectFont: 'body01',
  marginBottom: '$12',
  maxWidth: '250px',
})
const PromoTileMedia = styled('div', {
  borderRadius: '$r1',
  overflow: 'hidden',
  marginBottom: '$28',
})
const Placeholder = styled('div', {
  paddingBottom: '100%',
  background: '$gray300',
})
export const Card: FC<CardProps> = ({
  title,
  link,
  subtitle,
  image,
  ratio = 'square',
}) => {
  if (!link) return null
  return (
    <PromoTileWrap>
      <Link {...link}>
        <PromoTileMedia>
          {image ? (
            <Media {...image} sizes={'400px'} hardcropRatio={ratio} />
          ) : (
            <Placeholder />
          )}
        </PromoTileMedia>
        <PromoTileTitle>{title}</PromoTileTitle>
        {subtitle && <PromoTileSubtitle>{subtitle}</PromoTileSubtitle>}
      </Link>
    </PromoTileWrap>
  )
}
