import { FC } from 'react'
import { HeaderTab } from '@magal/models'
import { styled } from '@magal/styles'
import { Link } from '@magal/components'
import { Card } from './Card'

type HeaderTabProps = {
  tab: HeaderTab
}

const Root = styled('div', {
  width: '100%',
  columnGap: '$64',
  rowGap: '$24',
  display: 'grid',
  paddingLeft: '$64',
  '@lg': {
    gridTemplateColumns: '1fr 3fr 2fr',
  },
  variants: {
    onlyTiles: {
      true: {
        '@lg': {
          gridTemplateColumns: '1fr 4fr',
        },
      },
    },
  },
})
const FeaturedLinksWrap = styled('div', {
  display: 'grid',
  alignContent: 'start',
  gridGap: '$12',
  paddingTop: '$32',
  '@lg': {
    paddingTop: '$16',
  },
})
const LinkGroupsWrap = styled('div', {
  display: 'grid',
  gridColumnGap: '$24',
  gridRowGap: '$32',
  paddingTop: '$16',
  '@lg': {
    gridTemplateColumns: 'repeat(5, auto)',
  },
})
const GroupTitle = styled('div', {
  projectFont: 'caps07',
  marginBottom: '$28',
  color: '$green_09',
})
const GroupLinks = styled('div', {
  display: 'grid',
  gridGap: '$12',
})
const GroupLink = styled(Link, {
  projectFont: 'body01',
  color: '$green_09',
  '&:hover': {
    textDecoration: 'underline',
  },
})
const FeaturedLink = styled(Link, {
  projectFont: 'caps03',
  color: '$green_09',
  whiteSpace: 'nowrap',
  '&:hover': {
    textDecoration: 'underline',
  },
})
const PromoTileWrap = styled('div', {
  display: 'flex',
  columnGap: '$20',
  maxWidth: '90%',
  '@lg': {
    maxWidth: '1200px',
  },
})

const PromoTile = styled('div', {
  maxWidth: '180px',
  width: '100%',
})

export const Tab: FC<HeaderTabProps> = ({ tab }) => {
  const { promoTile = [], linkGroups } = tab

  return (
    <Root
      onlyTiles={
        promoTile.length > 0 && (!linkGroups || linkGroups?.length === 0)
      }
    >
      <FeaturedLinksWrap>
        {tab.featuredLinks?.map((featuredLink) => (
          <FeaturedLink {...featuredLink.link} key={featuredLink.title}>
            {featuredLink.title}
          </FeaturedLink>
        ))}
      </FeaturedLinksWrap>
      {linkGroups && linkGroups?.length > 0 && (
        <LinkGroupsWrap>
          {linkGroups?.map((group) => (
            <div key={group.title}>
              <GroupTitle>{group.title}</GroupTitle>
              <GroupLinks>
                {group.links?.map((l) => (
                  <GroupLink key={l.title} {...l.link}>
                    {l.title}
                  </GroupLink>
                ))}
              </GroupLinks>
            </div>
          ))}
        </LinkGroupsWrap>
      )}
      <PromoTileWrap>
        {promoTile?.map((tile) => (
          <PromoTile>
            <Card key={tile.title} {...tile} ratio={'portrait3'} />
          </PromoTile>
        ))}
      </PromoTileWrap>
    </Root>
  )
}
