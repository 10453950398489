import { ImageCrop, ImageHotspot } from '@sanity/types'
import { LinkFieldType } from './link-field-type'
import {
  ShopifyVideoType,
  ShopifyExternalVideoType,
} from './shopify/shopify-media'

export type SanityImageType = {
  _type: 'image'
  asset: {
    _ref: string
    _type: 'reference'
  }
  width: number
  height: number
  crop?: ImageCrop
  hotspot?: ImageHotspot
  alt?: string
  unoptimized: boolean
}

export type MuxVideoType = {
  aspectRatio: string
  duration: number
  firstFrame: string
  height: number
  hls: string
  id: string
  mp4: string
  width: number
}

export type ShopifyImageType = {
  id: string
  src: string
  width: number
  height: number
  alt?: string | null
}

export type SanityImageMediaPayload = {
  type: 'sanity-image'
  image: SanityImageType
  mobileImage?: SanityImageType
}

export type MuxVideoMediaPayload = {
  type: 'mux-video'
  video: MuxVideoType
  mobileVideo: MuxVideoType
}

export type ShopifyVideoMediaPayload = {
  type: 'shopify-media-video'
  video: ShopifyVideoType
}

export type ShopifyExternalVideoMediaPayload = {
  type: 'shopify-media-external-video'
  video: ShopifyExternalVideoType
}

export type ShopifyImageMediaPayload = {
  type: 'shopify-image'
  image: ShopifyImageType
}

export type ShopifyMediaPayload =
  | ShopifyVideoMediaPayload
  | ShopifyExternalVideoMediaPayload
  | ShopifyImageMediaPayload

type UndefinedPayload = {
  type: 'undefined-payload'
}

export type MediaPayload =
  | SanityImageMediaPayload
  | MuxVideoMediaPayload
  | ShopifyImageMediaPayload
  | UndefinedPayload
  | ShopifyMediaPayload

export type MediaFieldType = {
  alt: string
  mediaPayload: MediaPayload
}

// Media with capiton: image and text description

export type MediaWithCaption = {
  caption?: string
  link?: LinkFieldType
  image?: MediaFieldType
}

export const ratios = {
  square: 1,
  landscape1: 0.42,
  landscape2: 0.64,
  portrait1: 1.1,
  portrait2: 1.25,
  portrait3: 1.334,
}

export type ImageSizes =
  | '420'
  | '768'
  | '1024'
  | '1400'
  | '1600'
  | '1920'
  | '2560'

export type MediaRatiosType =
  | keyof typeof ratios
  | Record<ImageSizes, keyof typeof ratios>
