import { ImageCrop } from '@sanity/types'
import { ImageSizes, MediaRatiosType, ratios } from '@magal/models'
type Props = {
  width: number
  ratioType?: MediaRatiosType
  height?: number
  crop?: ImageCrop
}

export const getMediaSizeByRatio = ({
  width,
  ratioType,
  height,
  crop,
}: Props) => {
  const hardCropValue = (() => {
    if (!ratioType) {
      return undefined
    }

    if (typeof ratioType === 'string') {
      return ratios[ratioType]
    }

    const widthKey = `${width}` as ImageSizes
    const ratioBreakpointType = ratioType[widthKey]

    return ratioBreakpointType ? ratios[ratioBreakpointType] : undefined
  })()

  if (hardCropValue) {
    return [width, Math.floor(width * hardCropValue)]
  }

  if (crop && height) {
    return [
      width * (1 - (crop.left + crop.right)),
      height * (1 - (crop.bottom + crop.top)),
    ]
  }

  return [width, height]
}
