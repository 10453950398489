import { getLocaleRegionIdFromPath } from '@magal/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { ServiceResponse, SiteConfiguration } from '@magal/models'
import { createSEOProjection } from './projections/createSEOProjection'
import { DEFAULT_REGION_ID } from '@magal/configs'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createLinkProjection } from './projections/createLinkProjection'
import { createMediaProjection } from './projections/createMediaProjection'
import { createBlockContentProjection } from './projections/createBlockContentProjection'
import { captureException, withScope } from '@sentry/nextjs'
import { COLOR_PROJECTION } from './projections/colorProjection'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<ServiceResponse<SiteConfiguration>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BLOCK_CONTENT_PROJECTION = createBlockContentProjection(localeId)

  const countrySelectorQuery = groq`
  *[_type == "countrySelector"] {
    enabled,
    title,
    text,
    icon${MEDIA_PROJECTION},
    borderColor,
    bgColor,
    textColor,
    buttons[] {
      title,
      link,
      for
    },
    ${coalesceLocaleField('stayTitle', localeId)},
  }[0]`

  const headerQuery = groq`
  *[_type == "header"] {
    promoBar {
      enabled,
      showTrustpilot,
      captionsTool[]{
        ${coalesceLocaleField('caption', localeId)},
        ${coalesceLocaleField('tooltip', localeId)},
        bgColor,
        textColor
      },
      captions[] {
        ${coalesceLocaleField('caption', localeId)},
      },
      sliderTime,
      hoursToFinish,
      enabledTimer,
      backgroundColor,
      textColor
    },
    promoBanner {
      enabled,
      showTrustpilot,
      captionsTool[]{
        ${coalesceLocaleField('caption', localeId)},
        ${coalesceLocaleField('tooltip', localeId)},
        bgColor,
        textColor
      },
      captions[] {
        ${coalesceLocaleField('caption', localeId)},
      },
      dateItFinishes,
      enabledTimer,
      backgroundColor,
      textColor
    },
    "tabs": coalesce(headerNavigation_${regionId}, headerNavigation_${DEFAULT_REGION_ID}) -> {
      tabs[] {
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
        type,
        type == 'links' => {
          linkGroups[]{
            ${coalesceLocaleField('title', localeId)},
            links[]{
              ${coalesceLocaleField('title', localeId)},
              link${LINK_PROJECTION},
            }
          },
          featuredLinks[]{
            ${coalesceLocaleField('title', localeId)},
            link${LINK_PROJECTION},
          },
        },
        showPromoTile => {
          promoTile[]{
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('subtitle', localeId)},
            link${LINK_PROJECTION},
            image${MEDIA_PROJECTION},
          }
        },
        backgroundColor${COLOR_PROJECTION},
        textColor${COLOR_PROJECTION}
      }
    }.tabs
  }[0]
  `

  const footerQuery = groq`
  *[_type == "footer"] {
    "footerNavigation": coalesce(footerNavigation_${regionId}, footerNavigation_${DEFAULT_REGION_ID}) -> {
      linkGroups[]{
        ${coalesceLocaleField('title', localeId)},
        links[]{
          ${coalesceLocaleField('title', localeId)},
          link${LINK_PROJECTION},
        }
      },
      bottomLinks[]{
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
      },
      socials,
      paymentMethods[]{
        title,
        icon${MEDIA_PROJECTION}
      }
    }
  }[0].footerNavigation
  `

  const newsletterQuery = groq`
    *[_type == "newsletter"] {
      footer {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${BLOCK_CONTENT_PROJECTION},
      },
      subscribe {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${BLOCK_CONTENT_PROJECTION},
      },
    }[0]
  `

  const seoQuery = groq`
    *[_type == "seo"]${createSEOProjection(localeId)}[0]
  `

  const cartQuery = groq`
    *[_type == "cart"] {
      ${coalesceLocaleField('cartInfo', localeId)},
      emptyCartLinks[] {
        link${LINK_PROJECTION},
        link_de${LINK_PROJECTION},
        link_fr${LINK_PROJECTION},
        ${coalesceLocaleField('label', localeId)},
      },
      cartUpsellsTitle,
      cartUpsellsEnabled,
      "cartUpsells": cartUpsells[]{
        title,
        "handle": product->store.slug.current,
        "original": originalProduct->store.slug.current
      },
      freeDeliveryMinimumAmount,
      "cartReward": {
        "enabled": showRewardProduct,
        "handle": rewardProduct->store.slug.current,
        "minimum": rewardProductMinimumAmount
      }
    }[0]
  `

  const searchQuery = groq`
    *[_type == "search"] {
      ${coalesceLocaleField('title', localeId)},
      links[]{
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
        link_fr${LINK_PROJECTION},
        link_de${LINK_PROJECTION},
      },
    }[0]
  `

  const trustpilotQuery = groq`
    *[_type == "trustpilot"] {
      mainLink${LINK_PROJECTION},
      rating,
      count,
    }[0]
  `

  const discountQuery = groq`
    *[_type == "discount"] {
      enableDiscount,
      percentage,
    }[0]
  `

  const storyIconsQuery = groq`
    *[_type == "storyIcons"] {
      icons[]{
        ${coalesceLocaleField('title', localeId)},
        icon${MEDIA_PROJECTION},
        color,
        link${LINK_PROJECTION},
        link_fr${LINK_PROJECTION},
        link_de${LINK_PROJECTION},
      },
    }[0]
  `

  const productQuery = groq`{
  "colorsConfig": *[_type == "productColor"] {
      shopifyId,
      ${coalesceLocaleField('label', localeId)},
      "color": color.hex
    },
  "tags": *[_type == "productsTags"] {
      shopifyTag,
      ${coalesceLocaleField('label', localeId)},
      "bgColor": bgColor.hex,
      "textColor": textColor.hex
    },
  "backgroundColor": *[_type == "productsSettings"] {
      backgroundColor${COLOR_PROJECTION}
    }[0],
  "upsells": *[_type == "productUpsells"] {
      enabled,
      ${coalesceLocaleField('title', localeId)},
      "upsellItems": upsellItems[]{
        "handle": product->store.slug.current,
        "original": originalProduct->store.slug.current
      },
    }[0],
  "stringColors": *[_type == "productsSettings"] {
      stringColors[]{
        ${coalesceLocaleField('label', localeId)},
        "color": color.hex,
      },
    }[0],
    "colorOrder": *[_type == "productsSettings"] {
      colorOrder[]{
        color
      },
    }[0],
  }`

  const siteConfigurationQuery = groq`{
      "header": ${headerQuery},
      "footer": ${footerQuery},
      "newsletter": ${newsletterQuery},
      "seo": ${seoQuery},
      "cart": ${cartQuery},
      "search": ${searchQuery},
      "trustpilot": ${trustpilotQuery},
      "discount": ${discountQuery},
      "storyIcons": ${storyIconsQuery},
      "product": ${productQuery},
      "countrySelector": ${countrySelectorQuery}
    }
  `

  const siteConfigurationData = await getClient(preview)
    .fetch(siteConfigurationQuery)
    .catch((e) => {
      withScope(function (scope) {
        scope.setTransactionName('getSiteConfiguration')
        captureException(e)
      })
      return { status: 'ERROR', errors: [e] }
    })

  const getProductColorsConfig = () => {
    if (!siteConfigurationData.product) {
      return {}
    }
    return siteConfigurationData.product.colorsConfig.reduce(
      (
        acc: Record<string, { label: string; color: string }>,
        val: { shopifyId: string; label?: string; color?: string },
      ) => {
        const { shopifyId, label, color } = val
        return {
          ...acc,
          [shopifyId]: { label, color },
        }
      },
      {},
    )
  }

  const data = {
    ...siteConfigurationData,
    product: {
      colorsConfig: getProductColorsConfig(),
      tags: siteConfigurationData.product.tags.reduce(
        (
          acc: Record<
            string,
            { label?: string; bgColor?: string; textColor?: string }
          >,
          val: {
            shopifyTag: string
            label?: string
            bgColor?: string
            textColor?: string
          },
        ) => {
          const { shopifyTag, label, bgColor, textColor } = val

          return {
            ...acc,
            [shopifyTag]: { label, bgColor, textColor },
          }
        },
        {},
      ),
      backgroundColor: siteConfigurationData.product.backgroundColor,
      stringColors: siteConfigurationData.product.stringColors,
      colorOrder: siteConfigurationData.product.colorOrder.colorOrder.map(
        (item: Record<string, ''>) => item.color,
      ),
      upsells: siteConfigurationData.product.upsells,
    },
  }

  return { status: 'OK', data }
}
